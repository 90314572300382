import { VuexModule, Mutation, Module } from "vuex-module-decorators";
import Store from "@/store/index";
import NavigationModel from "@/models/NavigationModel";

@Module({ dynamic: true, store: Store, name: "tab", namespaced: true })
export default class TabModule extends VuexModule {
  private tabs: Array<NavigationModel> = [];

  @Mutation
  public setTabs(tabs: Array<NavigationModel>): void {
    this.tabs = tabs;
  }

  get getTabs(): Array<NavigationModel> {
    return this.tabs;
  }
}
