
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import TagModel from '@/models/TagModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import { VForm } from '@/types/vForm';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class TagNameDataTable extends Vue {
  @Ref() private readonly TagForm!: VForm;
  @Prop(String) readonly id!: string;
  private isEditing = false;
  private search = '';
  private tagTitle = '';
  private dialog = false;

  private TagModel: TagModel = new TagModel();
  private deleteTagDialog = false;
  private allTags: Array<TagModel> = [];
  private rules: any = validations.VALIDATION_RULES;
  private headers = [
    { text: 'Tag Name', value: 'name', class: 'table-headers' },
    {
      text: ' ',
      value: 'actions',
      sortable: false,
      class: 'table-headers',
      align: 'right',
    },
  ];
  private Tags = [
    { name: 'Tag 1' },
    { name: 'Tag 2' },
    { name: 'Tag 3' },
    { name: 'Tag 4' },
    { name: 'Tag 5' },
    { name: 'Tag 6' },
    { name: 'Tag 7' },
    { name: 'Tag 8' },
    { name: 'Tag 9' },
    { name: 'Tag 10' },
  ];
  private async created() {
    this.getTags();
  }

  private async getTags() {
    this.allTags = await apiService.getAll<TagModel>('/tag');
  }

  private async refreshTags() {
    this.getTags();
  }

  private async saveTag() {
    if (!this.TagForm.validate()) {
      return snackbarModule.snackError('Invalid form');
    }
    if (this.isEditing) {
      try {
        let Tag = await apiService.put<TagModel>(
          '/tag',
          this.TagModel.tagId,
          this.TagModel
        );
        this.TagModel = new TagModel();
        this.closeDialog();
        this.$emit('refreshProducts');
        return snackbarModule.snackSuccess('Tag edited successfully');
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
    } else {
      try {
        await apiService.post('/tag', this.TagModel).then(() => {
          return snackbarModule.snackSuccess('Tag added successfully');
        });
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
      this.$emit('refreshProducts');
      this.closeDialog();
    }
  }
  private addDialog() {
    this.dialog = true;
    this.tagTitle = 'Add Tag';
  }

  private async editTag(item: any) {
    this.TagModel = item;
    this.dialog = true;
    this.isEditing = true;
    this.tagTitle = 'Edit Tag';
  }
  private deleteTag(item: any) {
    this.TagModel = item;
    this.deleteTagDialog = true;
  }
  private async confirmDeleteTag() {
    let TagName = this.TagModel.name;
    try {
      let Tag = await apiService.delete('/tag', this.TagModel.tagId);
      this.closeDialog();
      this.$emit('refreshProducts');
      return snackbarModule.snackSuccess(`Tag ${TagName} deleted successfully`);
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async closeDialog() {
    this.TagModel = new TagModel();
    this.isEditing = false;
    this.deleteTagDialog = false;
    this.dialog = false;
    await this.refreshTags();
  }
}
