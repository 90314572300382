const validations = {
  REQUIRED: "Required",
  EMAIL_FORMAT: "Email format is not valid",
  NUMBER: "Invalid value",

  NOT_SPACE: "Name must contain at least one symbol other than space",
  POSITIVE_NUMBER: "Must be positive number",
  ITERSATION_VALUE: "Minimum 4 digits",
  TWO_DECIMALS: "Only 2 decimals are allowed after dot",
  NO_DECIMALS: "Only integer is allowed",
  SHORTHER_THEN_XXX_SYMBOLS: (xxx: number) =>
    `Name must contain less or equal to ${xxx} symbols`,
  SHORTHER_THEN_XXX_CHARACTERS: (xxx: number) =>
    `Must be less or equal to ${xxx} characters`,
  LESS_OR_EQUAL: (v: number, isEqual: boolean) =>
    `Must be less than ${isEqual ? "or equal to" : ""} ${v}.`,
  GREATER_OR_EQUAL: (v: number, isEqual: boolean) =>
    `Must be greater than ${isEqual ? "or equal to" : ""} ${v}.`,

  PORTFOLIO_ACTION_EXISTS: "An option with the same name already exists",

  PASSWORD_AT_LEAST_ONE_CHARACTER:
    "Password must contain at least one special character",
  PASSWORD_AT_LEAST_ONE_UPPER_CASE:
    "Password must contain at least 1 uppercase alphabetical character",
  PASSWORD_AT_LEAST_ONE_LOWER_CASE:
    "Password must contain at least 1 lowercase alphabetical character",
  PASSWORD_AT_LEAST_ONE_NUMBER:
    "Password must contain at least 1 numeric character",
  PASSWORD_AT_LEAST_EIGHT_SYMBOLS:
    "Password must be longer or equal to 8 symbols",
  PASSWORD_NO_SPACE: "Spaces are not allowed",

  VALIDATION_RULES: {
    file: (v: any) => (v && v.size > 0) || "File is required",
    email: (value: string) =>
      (value !== undefined &&
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )) ||
      validations.EMAIL_FORMAT,
    required: (value: string) =>
      (value !== null && value !== "") || validations.REQUIRED,
    number: (value: string) =>
      (value !== undefined && /^(-){0,1}(\d){1,}(.\d*){0,1}$/.test(value)) ||
      validations.NUMBER,
    not_space: (value: string) =>
      !value ? true : /[^\s]/.test(value) || validations.NOT_SPACE,
    shorter_then_128_symbols: (value: string) =>
      !value
        ? true
        : value.length <= 128 || validations.SHORTHER_THEN_XXX_SYMBOLS(128),
    shorter_then_50_symbols: (value: string) =>
      !value
        ? true
        : value.length <= 50 || validations.SHORTHER_THEN_XXX_CHARACTERS(50),
    shorter_then_256_symbols: (value: string) =>
      (value !== undefined && value !== null && value.length <= 256) ||
      validations.SHORTHER_THEN_XXX_SYMBOLS(256),
    positive_number: (value: string) =>
      (value !== undefined && Number.parseFloat(value) > 0) ||
      validations.POSITIVE_NUMBER,
    two_decimal: (value: string) =>
      (value !== undefined &&
        !(
          value.toString().indexOf(".") > -1 &&
          value.toString().split(".")[1].length > 2
        )) ||
      validations.TWO_DECIMALS,
    no_decimal: (value: string) =>
      (value !== undefined && value.toString().indexOf(".") === -1) ||
      validations.NO_DECIMALS,
    lessOrEqual(value: number, isEqual: boolean = true) {
      if (isEqual) {
        return (v: string) =>
          (v !== undefined && Number.parseFloat(v) <= value) ||
          validations.LESS_OR_EQUAL(value, isEqual);
      }
      return (v: string) =>
        (v !== undefined && Number.parseFloat(v) < value) ||
        validations.LESS_OR_EQUAL(value, isEqual);
    },
    greaterOrEqual(value: number, isEqual: boolean = true) {
      if (isEqual) {
        return (v: string) =>
          (v !== undefined && Number.parseFloat(v) >= value) ||
          validations.GREATER_OR_EQUAL(value, isEqual);
      }
      return (v: string) =>
        (v !== undefined && Number.parseFloat(v) > value) ||
        validations.GREATER_OR_EQUAL(value, isEqual);
    },

    PASSWORD: {
      at_least_one_character: (value: string) =>
        (value !== undefined &&
          value !== null &&
          /(?=.[!@#\$%\^&])/.test(value)) ||
        validations.PASSWORD_AT_LEAST_ONE_CHARACTER,
      at_least_one_upper_case: (value: string) =>
        (value !== undefined && value !== null && /(?=.*[A-Z])/.test(value)) ||
        validations.PASSWORD_AT_LEAST_ONE_UPPER_CASE,
      at_least_one_lower_case: (value: string) =>
        (value !== undefined && value !== null && /(?=.*[a-z])/.test(value)) ||
        validations.PASSWORD_AT_LEAST_ONE_LOWER_CASE,
      at_least_one_number: (value: string) =>
        (value !== undefined && value !== null && /(?=.*[0-9])/.test(value)) ||
        validations.PASSWORD_AT_LEAST_ONE_NUMBER,
      min_length_8: (value: string) =>
        (value !== undefined && value !== null && value.length >= 7) ||
        validations.PASSWORD_AT_LEAST_EIGHT_SYMBOLS,
      no_spaces: (value: string) =>
        (value !== undefined && value !== null && !/\s/.test(value)) ||
        validations.PASSWORD_NO_SPACE,
    },
  },
};

export class validationsFactory {}

export default validations;
