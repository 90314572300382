
import { Vue, Component, Ref } from 'vue-property-decorator';
import { VForm } from '@/types/vForm';
import { getModule } from 'vuex-module-decorators';
import SnackbarModule from '@/store/modules/snackbarModule';
import apiService from '@/services/apiService';
import FolderDataTable from '@/components/FolderDataTable.vue';
import FileDataTable from '@/components/FileDataTable.vue';
import UserDataTable from '@/components/UserDataTable.vue';
import UserApprovalTable from '@/components/UserApprovalTable.vue';
import LocationDataTable from '@/components/LocationDataTable.vue';
import TagNameDataTable from '@/components/TagNameDataTable.vue';
import EnquiryDataTable from '@/components/EnquiryDataTable.vue';
import DivisionDataTable from '@/components/DivisionDataTable.vue';
import LatestNews from '@/components/LatestNews.vue';
import Notification from '@/components/Notification.vue';
import FilesForApprovalDataTable from '@/components/FilesForApprovalDataTable.vue';
import FileForApprovalModel from '@/models/FileForApprovalModel';
import TagModel from '@/models/TagModel';
import UserModel from '@/models/UserModel';
import ContractorsSseChart from '@/components/ContractorsSseChart.vue';
import DocumentsAccessedChart from '@/components/DocumentsAccessedChart.vue';
@Component({
  components: {
    FolderDataTable,
    FileDataTable,
    UserDataTable,
    UserApprovalTable,
    LocationDataTable,
    TagNameDataTable,
    DivisionDataTable,
    EnquiryDataTable,
    FilesForApprovalDataTable,
    ContractorsSseChart,
    DocumentsAccessedChart,
    LatestNews,
    Notification
  },
})
export default class Admin extends Vue {
  private selected = 'e1';
  private isAnsaAdmin = false;
  private refreshProductKey: number = 0;
  private allTags: Array<TagModel> = [];
  private refreshUserKey: number = 0;
  private refreshCompaniesKey: number = 0;
  private allFilesForApproval: FileForApprovalModel[] = [];
  private allUsersForApproval: UserModel[] = [];
  private allUsersForApprovalCount: number = 0;
  private async created() {
    await this.getUser();
    await this.getFilesForApproval();
    await this.getUsersForApproval();
  }

  private updateUserTabCount(tabNumber:number) {
    this.allUsersForApprovalCount += tabNumber;
  }

  private async getTags() {
    this.allTags = await apiService.getAll<TagModel>('/tag');
  }
  private async getUser() {
    this.isAnsaAdmin = this.user.role == 'ADM' ? true : false;
  }
  get user() {
    return { ...this.$store.state.user };
  }
  private async getFilesForApproval() {
    let response = await apiService.getAll<FileForApprovalModel>('/document');
    this.allFilesForApproval = response.filter((file) => {
      return file.folderId == null;
    });
  }
  private async getUsersForApproval() {
    let response = await apiService.getAll<UserModel>('/user');
    this.allUsersForApproval = response.filter((user) => {
      return user.isActive == false;
    });
    this.allUsersForApprovalCount= this.allUsersForApproval.length
  }
}
