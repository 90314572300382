
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import SnackbarModule from '@/store/modules/snackbarModule';

@Component
export default class Snackbar extends Vue {
  private snackbarModule: SnackbarModule = getModule(SnackbarModule);
  private show = false;
  private message = '';
  private color = '';

  @Watch('storeMessage')
  onMessageChanged(): void {
    if (this.storeMessage) {
      this.show = true;
    }
  }

  @Watch('getShow')
  onShowChanged(): void {
    if (!this.show) {
      this.snackbarModule.snackInfo('');
    }
  }

  get storeMessage(): string {
    return this.snackbarModule.message;
  }

  get storeColor(): 'info' | 'error' | 'success' | 'primary' | '' {
    return this.snackbarModule.color;
  }

  get getShow(): boolean {
    return this.show;
  }
}
