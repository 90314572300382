
  import { Vue, Component } from 'vue-property-decorator';
  import VueApexCharts from 'vue-apexcharts';
  import UserModel from '@/models/UserModel';
  import apiService from '@/services/apiService';
  import DivisionModel from '@/models/DivisionModel';
  
  @Component({ components: { VueApexCharts } })
  export default class UserChartChart extends Vue {
    private allUsers: Array<UserModel> = [];
  
    private chartOptions = {
      labels: [] as string[] | null | undefined,
      chart: {
        type: 'pie',
        size: '100%',
        height: '350px',
        toolbar: {
          show: false,
        },
      },
      colors: ['#0A174E', '#F5D042'],
      dataLabels: {
        enabled: true,
        formatter: (val: number, opts: any) => {
          return this.chartOptions.labels?.[opts.seriesIndex] ?? '';
        },
      },
      legend: {
        show: true,
        fontSize: '18px',
        labels: {
          formatter: (seriesName: string, opts: any) => {
            return this.chartOptions.labels?.[opts.seriesIndex] ?? '';
          },
        },
      },
    };
  
    private async created() {
      await this.getUsers();
  
      const updatedOptions = { ...this.chartOptions };
      updatedOptions.labels = ['Contractors', 'SSE staff'];
      updatedOptions.legend.labels.formatter = (val: any, opts: any) => {
        return this.divisionLabels[opts.seriesIndex];
      };
      this.chartOptions = updatedOptions;
    }
  
    private async getUsers() {
      this.allUsers = await apiService.getAll<UserModel>('/user');
    }
  
    get divisionLabels() {
      return ['Contractors', 'SSE staff'];
    }
  
    get chartSeries() {
      var users = this.allUsers;
      var contractors = 0;
      var sseStaff = 0;
      users.filter(u => u.isActive).forEach((user) => {
        if (user.emailAddress.endsWith('@sse.com')) {
          sseStaff++;
        } else {
          contractors++;
        }
      });
      return [contractors, sseStaff];
    }
  }
  