import { Guid } from 'guid-typescript';
export default class EnquiryModel {
  enquiryId: Guid = Guid.createEmpty();
  name: string = '';
  emailAddress: string = '';
  subject: string = '';
  description: string = '';
  trimmedDescription: string = '';
  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
