
import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import Component from 'vue-class-component';
import Navbar from '@/components/layout/Navbar.vue';
import UserModule from '@/store/modules/userModule';
import Snackbar from '@/components/Snackbar.vue';

const userModule = getModule(UserModule);

@Component({ components: { Navbar, Snackbar } })
export default class App extends Vue {
  private getAuthorized = true;

  get computedAuth() {
    return this.$store.state.token.accessToken;
  }
  get isLoggedIn() {
    return this.$store.state.isLoggedIn;
  }
}
