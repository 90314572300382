import { Guid } from 'guid-typescript';
import DocumentTagModel from './DocumentTagModel'

export default class FileForApprovalModel {
  documentId: Guid = Guid.createEmpty();
  folderId?: Guid = Guid.createEmpty();
  name: string = '';
  documentTags = Array<DocumentTagModel>();
  newTags: string = ''
}
