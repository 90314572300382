import { Guid } from 'guid-typescript';
import Location from './LocationModel';
import Division from './DivisionModel';
export default class UserModel {
  userId: Guid = Guid.createEmpty();
  name: string = '';
  firstname: string = '';
  lastname: string = '';
  emailSuffixId: string = '';
  role: string = '';
  emailAddress: string = '';
  password: string = '';
  roleDescription: string = '';
  enabled: boolean = false;
  companyId: string = '';
  companyName: string = '';
  jobTitle: string = '';
  countryCode: string = '';
  contactTelephone: string = '';
  isMainContact: boolean = false;
  isActive: boolean = true;
  locationId: Guid = Guid.createEmpty();
  divisionId: Guid = Guid.createEmpty();
  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
