import UserModel from '@/models/UserModel';
import PassWordModel from '@/models/UserChangePasswordModel';
import Vue from 'vue';
import Vuex from 'vuex';
import apiService from '@/services/apiService';
import resetpassword from '@/store/modules/resetPasswordModule';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {} as UserModel,
    token: {
      accessToken: '',
      refreshToken: '',
    },
    isAdmin: false,
    isLoggedIn: false,
    searchedFileIds: [],
  },
  mutations: {
    setUserData(state, data) {
      state.user = data;
      state.isAdmin = state.user.role === 'ADM' ? true : false;
    },
    setSearchedFileIds(state, data) {
      state.searchedFileIds = data;
    },
    setAccessToken(state, data) {
      state.token.accessToken = data;
    },

    setRefreshToken(state, data) {
      state.token.refreshToken = data;
    },
    setLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
  },
  actions: {
    async getUser({ commit }) {
      await apiService
        .getUserData()
        .then((response) => {
          commit('setUserData', response);
          commit('setLoggedIn', true);
        })
        .catch(() => {
          commit('setLoggedIn', false);
        });
    },
    async updateUser({ commit }, user: UserModel) {
      await apiService
        .put('user', this.state.user.userId, user)
        .then((response) => {
          commit('setUserData', response);
        });
    },
    async updateSearchedFileIds({ commit }, searchedFileIds: string[]) {
      commit('setSearchedFileIds', searchedFileIds);
    },
  },
  getters: {
    user: (state) => state.user,
    isAdmin: (state) => state.user.role === 'ADM',
    searchedFileIds: (state) => state.searchedFileIds,
  },
  modules: {
    resetpassword,
  },
});
