import PassWordModel from "@/models/UserChangePasswordModel";
import apiService from "@/services/apiService";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async updatePassword({ commit }: any, password: PassWordModel) {
      const response = await apiService.post("user/changePassword/", password);
      return response;
    },
    async resetPassword({ commit }: any, password: PassWordModel) {
      const response = await apiService.postResetPassword(
        password.passwordId,
        password
      );
      return response;
    },
  },
  getters: {},
  modules: {},
};
