
import { Component, Vue, Ref } from 'vue-property-decorator';
import NotificationModel from '@/models/NotificationModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import { VForm } from '@/types/vForm';
import { AxiosError } from 'axios';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class LatestNews extends Vue {
  @Ref() private readonly notificationForm!: VForm;
  private isEditing = false;
  private search = '';
  private dialog = false;
  private notifyDialog = false;
  private latestNotification: Array<NotificationModel> = [];
  private rules: any = validations.VALIDATION_RULES;
  private notificationModel: NotificationModel = new NotificationModel();
 
  private async created() {
    this.getNotification();
  }

  private notifyAllUsers() {
    this.notifyDialog = true;
  }

  async submitNotification() {
    if (!this.notificationForm.validate()) {
      return snackbarModule.snackError('Invalid submission');
    }
    if(this.notificationModel.notificationId) {
      await apiService.put(`notification`, Guid.parse(this.notificationModel.notificationId), this.notificationModel);
      this.getNotification();
      snackbarModule.snackSuccess('Notification Updated Successfully');
      return;
    } else{
      await apiService.post('notification', this.notificationModel);
      this.getNotification();
      snackbarModule.snackSuccess('Notification Saved Successfully');
    }
  }

  private async confirmNotify() {
    await apiService.post('notification/notify', this.notificationModel);
    snackbarModule.snackSuccess('Notification sent successfully');
    this.notifyDialog = false;
  }

  private async getNotification() {
    this.notificationModel = await apiService.getNotication<NotificationModel>('/notification');
  }
}
