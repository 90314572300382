
import { Component, Vue, Ref } from 'vue-property-decorator';
import NewsModel from '@/models/NewsModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import { VForm } from '@/types/vForm';
import { AxiosError } from 'axios';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class LatestNews extends Vue {
  @Ref() private readonly newsForm!: VForm;
  private isEditing = false;
  private search = '';
  private dialog = false;
  private notifyDialog = false;
  private rules: any = validations.VALIDATION_RULES;
  private newsModel: NewsModel = new NewsModel();
 
  private async created() {
    this.getNews();
  }

  private notifyAllUsers() {
    this.notifyDialog = true;
  }

  async submitNews() {
    if (!this.newsForm.validate()) {
      return snackbarModule.snackError('Invalid submission');
    }
    if(this.newsModel.newsId) {
      await apiService.put(`news`, Guid.parse(this.newsModel.newsId), this.newsModel);
      snackbarModule.snackSuccess('News Updated Successfully');
      return;
    } else{
      await apiService.post('news', this.newsModel);
      snackbarModule.snackSuccess('News Saved Successfully');
    }
  }

  private async getNews() {
    this.newsModel = await apiService.getNews<NewsModel>('/news');
  }
}
