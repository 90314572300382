
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import LocationModel from '@/models/LocationModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import { VForm } from '@/types/vForm';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class LocationDataTable extends Vue {
  @Ref() private readonly locationForm!: VForm;
  @Prop(String) readonly id!: string;
  private isEditing = false;
  private search = '';
  private dialog = false;
  private locationModel: LocationModel = new LocationModel();
  private deleteLocationDialog = false;
  private allLocations: Array<LocationModel> = [];
  private rules: any = validations.VALIDATION_RULES;
  private headers = [
    { text: 'Location Name', value: 'name', class: 'table-headers' },
    {
      text: ' ',
      value: 'actions',
      sortable: false,
      class: 'table-headers',
      align: 'right',
    },
  ];
  private locations = [
    { name: 'Location 1' },
    { name: 'Location 2' },
    { name: 'Location 3' },
    { name: 'Location 4' },
    { name: 'Location 5' },
    { name: 'Location 6' },
    { name: 'Location 7' },
    { name: 'Location 8' },
    { name: 'Location 9' },
    { name: 'Location 10' },
  ];
  private async created() {
    this.getLocations();
  }

  private async getLocations() {
    this.allLocations = await apiService.getAll<LocationModel>('/location');
  }

  private async refreshLocations() {
    this.getLocations();
  }

  private async saveLocation() {
    if (!this.locationForm.validate()) {
      return snackbarModule.snackError('Invalid form');
    }
    if (this.isEditing) {
      try {
        let location = await apiService.put<LocationModel>(
          '/location',
          this.locationModel.locationId,
          this.locationModel
        );
        this.locationModel = new LocationModel();
        this.closeDialog();
        this.$emit('refreshProducts');
        return snackbarModule.snackSuccess('Location edited successfully');
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
    } else {
      try {
        await apiService.post('/location', this.locationModel).then(() => {
          return snackbarModule.snackSuccess('Location added successfully');
        });
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
      this.$emit('refreshProducts');
      this.closeDialog();
    }
  }
  private async editLocation(item: any) {
    this.locationModel = item;
    this.dialog = true;
    this.isEditing = true;
  }
  private deleteLocation(item: any) {
    this.locationModel = item;
    this.deleteLocationDialog = true;
  }
  private async confirmDeleteLocation() {
    let locationName = this.locationModel.name;
    try {
      let location = await apiService.delete(
        '/location',
        this.locationModel.locationId
      );
      this.closeDialog();
      this.$emit('refreshProducts');
      return snackbarModule.snackSuccess(
        `Location ${locationName} deleted successfully`
      );
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async closeDialog() {
    this.locationModel = new LocationModel();
    this.isEditing = false;
    this.deleteLocationDialog = false;
    this.dialog = false;
    await this.refreshLocations();
  }
}
