import axios, { AxiosResponse } from 'axios';
import { Guid } from 'guid-typescript';
import UserModel from '@/models/UserModel';
import { getModule } from 'vuex-module-decorators';
import UserModule from '@/store/modules/userModule';
import UserTokenModel from '@/models/UserTokenModel';
import UserAuthModel from '@/models/UserAuthModel';
import UserPasswordResetRequestModel from '@/models/UserPasswordResetRequestModel';
import PassWordModel from "@/models/UserChangePasswordModel";
import UserDocumentModel from '@/models/UserDocumentModel';
const apiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'https://localhost:7123/api/'
      : 'https://ssesheportal.azurewebsites.net/api/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});
const apiClientNoAuth = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'https://localhost:7123/api/'
      : 'https://ssesheportal.azurewebsites.net/api/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
});

apiClient.interceptors.request.use((request) => {
  const tokenString: string | null = localStorage.getItem('token');

  if (tokenString) {
    const token = JSON.parse(tokenString);

    if (token) {
      request.headers
        ? (request.headers['Authorization'] = `Bearer ${token.accessToken}`)
        : null;
    }
  }

  return request;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (
        error.config.url != apiClient.defaults.baseURL + 'auth/refreshtoken' &&
        error.response.status === 401 &&
        error.config &&
        !error.config._retry
      ) {
        const userModule = getModule(UserModule);

        try {
          const tokenString: string | null = localStorage.getItem('token');

          if (tokenString) {
            const token = JSON.parse(tokenString);

            await userModule.refreshToken(token);
          } else {
            return Promise.reject(error);
          }
        } catch {
          await userModule.logout();
          return Promise.reject(error);
        }

        error.config._retry = true;
        return apiClient(error.config);
      }

      return Promise.reject(error);
    }
  }
);

export default {
  getBaseURL() {
    return apiClient.defaults.baseURL;
  },
  async postLogin(credentials: UserAuthModel): Promise<UserTokenModel> {
    const response = await apiClient.post<UserTokenModel>(
      'auth/token',
      credentials
    );
    return response.data;
  },
  async refreshToken(credentials: UserTokenModel): Promise<UserTokenModel> {
    const response = await apiClient.post<UserTokenModel>(
      'auth/refreshToken',
      credentials
    );
    return response.data;
  },
  async get<T>(url: string, id: Guid): Promise<T> {
    const reponse = await apiClient.get(url + '/' + id);
    return reponse.data;
  },
  async getFile(
    url: string,
    id: Guid
  ): Promise<{ file: Blob; filename: string }> {
    const response = await apiClient.get(`document/${id}`, {
      responseType: 'blob',
    });

    const contentDisposition = response.headers['content-disposition'];
    const filenameRegex = /filename\*=UTF-8''([^;]+)|filename=([^;]+)/;
    const filenameMatch = filenameRegex.exec(contentDisposition || '');

    const filename = filenameMatch
      ? decodeURI(filenameMatch[1] || filenameMatch[2])
      : 'file';

    return { file: response.data, filename };
  },

  async getAll<T>(url: string): Promise<Array<T>> {
    const response = await apiClient.get<Array<T>>(url);
    return response.data;
  },
  async getNews<T>(url: string): Promise<T> {
    const reponse = await apiClient.get(url);
    return reponse.data;
  },
  async getNotication<T>(url: string): Promise<T> {
    const reponse = await apiClient.get(url);
    return reponse.data;
  },
  async getAllNoAuth<T>(url: string): Promise<Array<T>> {
    const response = await apiClientNoAuth.get<Array<T>>(url);
    return response.data;
  },
  async getAllById<T>(url: string, id: Guid): Promise<Array<T>> {
    const response = await apiClient.get<Array<T>>(url + '/' + id);
    return response.data;
  },
  async put<T>(url: string, id: Guid, model: any): Promise<T> {
    const reponse = await apiClient.put<T>(url + '/' + id, model);
    return reponse.data;
  },
  async putPassword<T>(
    id: Guid,
    currentPassword: string,
    newPassword: string
  ): Promise<T> {
    const response = await apiClient.put<T>('user/updatePassword' + '/' + id, {
      currentPassword: currentPassword,
      newPassword: newPassword,
    });
    return response.data;
  },
  async putApproveUser<T>(
    url: string,
    userId: Guid,
    isApproved: boolean,
    rejectionReason?: string
  ): Promise<T> {
    const queryParams =
      rejectionReason !== undefined
        ? `?rejectionReason=${rejectionReason}`
        : '';
    const response = await apiClient.put<T>(
      `${url}/${userId}${queryParams}`,
      isApproved
    );
    return response.data;
  },

  async approveFile<T>(url: string, id: Guid, folderId: Guid): Promise<T> {
    const reponse = await apiClient.put<T>(url + '/' + id + '/' + folderId);
    return reponse.data;
  },
  async post<T>(url: string, model: any): Promise<T> {
    const response = await apiClient.post<T>(url, model);
    return response.data;
  },
  async postNoAuth<T>(url: string, model: any): Promise<T> {
    const response = await apiClientNoAuth.post<T>(url, model);
    return response.data;
  },
  async uploadFile<T>(url: string, file: File, folderId?: string): Promise<T> {
    const formData = new FormData();
    if (folderId) {
      formData.append('folderId', folderId);
    }
    formData.append('file', file);

    const response = await apiClient.post<T>(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  },
  async delete(url: string, id: any) {
    return apiClient.delete(url + '/' + id);
  },
  async sendPasswordReset(credentials: UserPasswordResetRequestModel) {
    return apiClient.post('user/sendpasswordreset', credentials);
  },
  async postResetPassword( id: Guid, model: PassWordModel) {
    return await apiClient.post('user/resetPassword' + '/' + id, model);
  },

  async getUserData(): Promise<UserModel> {
    const response = await apiClient.get('user/getUserData');
    return response.data;
  },

  async getB64string<T>(url: string, formData: any): Promise<T> {
    const response = await apiClient.post<T>(url, formData);
    return response.data;
  },
  async updateFileRequest<T>(url: string, id: Guid, formData: any): Promise<T> {
    const response = await apiClient.put<T>(url + '/' + id, formData);
    return response.data;
  },
  async updateStatus<T>(url: string, id: Guid, status: string): Promise<T> {
    const response = await apiClient.put<T>(url + '/' + id + '/' + status);
    return response.data;
  },
  async getDocument(documentId: Guid) {
    const response: any = await apiClient.get(
      '/request/download/' + documentId,
      {
        responseType: 'blob',
      }
    );
    const file = {
      data: response,
    };
    return file;
  },
  async incrementDocumentCount<T>(id: Guid): Promise<T> {
    const reponse = await apiClient.put<T>('/Document/' + id);
    return reponse.data;
  },
  async changeFileName<T>(id: Guid, name: string): Promise<T> {
    const response = await apiClient.put<T>(
      '/Document/changeFileName' + id + '/' + name
    );
    return response.data;
  },
  async postDocumentTagIds(url: string, model: any): Promise<any> {
      const response = await apiClient.post(url, model);
      return response.data;
  },
  async getDocumentActivity (userId: Guid): Promise<UserDocumentModel[]> {
    const response = await apiClient.get('useractivity/document/' + userId);
    return response.data;
  },
  async postDocumentActivity (documentId: Guid): Promise<any> {
    const response = await apiClient.post('/useractivity/document/' + documentId);
    return response.data;
  },
  async getDocumentsActivityByDivision (): Promise<number[]> {
    const response = await apiClient.get('useractivity/totalDocuments');
    return response.data;
  },

};
