import { Guid } from 'guid-typescript';
import DocumentTagModel from './DocumentTagModel'
export default class DocumentModel {
  fileName: string = '';
  contentType: string = '';
  folderId? : string = '';
  documentId: string = '';
  isUser:boolean = false;
  documentTags = Array<DocumentTagModel>();
  createdString : string = ''
}
