import { Guid } from 'guid-typescript';
export default class NotificationModel {
  notificationId:string = '';
  content: string = '';
  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
