
  import { Vue, Component } from 'vue-property-decorator';
  import VueApexCharts from 'vue-apexcharts';
  import apiService from '@/services/apiService';
  
  @Component({ components: { VueApexCharts } })
  export default class UserChartChart extends Vue {
    private allViewedDocuments: Array<number> = [];
  
    private chartOptions = {
      labels: [] as string[] | null | undefined,
      chart: {
        type: 'pie',
        size: '100%',
        height: '350px',
        toolbar: {
          show: false,
        },
      },
      colors: ['#00A4CCFF', '#F95700FF'],
      dataLabels: {
        enabled: true,
        formatter: (val: number, opts: any) => {
          return this.chartOptions.labels?.[opts.seriesIndex] ?? '';
        },
      },
      legend: {
        show: true,
        fontSize: '18px',
        labels: {
          formatter: (seriesName: string, opts: any) => {
            return this.chartOptions.labels?.[opts.seriesIndex] ?? '';
          },
        },
      },
    };
  
    private async created() {
      await this.getViewedDocuments();
  
      const updatedOptions = { ...this.chartOptions };
      updatedOptions.labels = ['SSE staff', 'Contractors'];
      updatedOptions.legend.labels.formatter = (val: any, opts: any) => {
        return this.divisionLabels[opts.seriesIndex];
      };
      this.chartOptions = updatedOptions;
    }
  
    private async getViewedDocuments() {
      this.allViewedDocuments = await apiService.getDocumentsActivityByDivision();

    }
  
    get divisionLabels() {
      return ['SSE staff', 'Contractors'];
    }
  
    get chartSeries() {
      return [this.allViewedDocuments[0], this.allViewedDocuments[1]];
    }
  }
  