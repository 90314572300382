import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { getModule } from 'vuex-module-decorators';
import UserModule from '@/store/modules/userModule';
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts';
import 'vuetify/dist/vuetify.min.css';

Vue.config.productionTip = false;

Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts);

new Vue({
  router,
  store,
  created() {
    const tokenString: string | null = localStorage.getItem('token');
    if (tokenString) {
      const token = JSON.parse(tokenString);
      const userModule = getModule(UserModule);
      userModule.setUserData(token);
    }
  },
  render: (h) => h(App),
  vuetify,
}).$mount('#app');
