
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import EnquiryModel from '@/models/EnquiryModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import { VForm } from '@/types/vForm';
import { AxiosError } from 'axios';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class EnquiryDataTable extends Vue {
  @Ref() private readonly divisionForm!: VForm;
  @Prop(String) readonly id!: string;
  private isEditing = false;
  private search = '';
  private dialog = false;
  private messageDialog = false;
  private allEnquiries: Array<EnquiryModel> = [];
  private enquiryModel: EnquiryModel = new EnquiryModel();
  private rules: any = validations.VALIDATION_RULES;
  private headers = [
    { text: 'Name', value: 'name', class: 'table-headers' },
    { text: 'Email Address', value: 'emailAddress', class: 'table-headers' },
    { text: 'Subject', value: 'subject', class: 'table-headers' },
    { text: 'Message', value: 'trimmedDescription', class: 'table-headers' },
    {
      text: ' ',
      value: 'actions',
      sortable: false,
      class: 'table-headers',
      align: 'right',
    },
  ];
  private async created() {
    this.getEnquiries();
  }

  private view(item: EnquiryModel) {
    this.messageDialog = true;
    this.enquiryModel = item;
  }
  private async deleteEnquiry(item : EnquiryModel) {
    let enquiryName = item.subject;
    try {
      let division = await apiService.delete(
        '/enquiry',
        item.enquiryId
      );
      this.getEnquiries();
      return snackbarModule.snackSuccess(
        `${enquiryName} deleted successfully`
      );
    } catch (error: any) {
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }

  private async getEnquiries() {
    this.allEnquiries = await apiService.getAll<EnquiryModel>('/enquiry');
  }
}
