import { VuexModule, Mutation, Action, Module } from "vuex-module-decorators";
import Store from "@/store/index";

export interface SnackbarConfig {
  message: string;
  color: "info" | "error" | "success" | "primary" | "";
}

@Module({ dynamic: true, store: Store, name: "snackbar", namespaced: true })
export default class SnackbarModule extends VuexModule {
  private snackbar: SnackbarConfig = {
    message: "",
    color: "",
  };

  @Mutation
  public snack(config: SnackbarConfig): void {
    this.snackbar.message = config.message;
    this.snackbar.color = config.color;
  }

  @Action({ commit: "snack" })
  public snackInfo(message: string) {
    return {
      message: message,
    };
  }

  @Action({ commit: "snack" })
  public snackError(message: string) {
    return {
      message: message,
      color: "error",
    };
  }

  @Action({ commit: "snack" })
  public snackSuccess(message: string) {
    return {
      message: message,
      color: "success",
    };
  }

  @Action({ commit: "snack" })
  public snackPrimary(message: string) {
    return {
      message: message,
      color: "primary",
    };
  }

  get message(): string {
    return this.snackbar.message;
  }

  get color(): "info" | "error" | "success" | "primary" | "" {
    return this.snackbar.color;
  }
}
