
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import DivisionModel from '@/models/DivisionModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import { VForm } from '@/types/vForm';
import { AxiosError } from 'axios';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class DivisionDataTable extends Vue {
  @Ref() private readonly divisionForm!: VForm;
  @Prop(String) readonly id!: string;
  private isEditing = false;
  private search = '';
  private dialog = false;
  private divisionModel: DivisionModel = new DivisionModel();
  private deleteDivisionDialog = false;
  private allDivisions: Array<DivisionModel> = [];
  private rules: any = validations.VALIDATION_RULES;
  private headers = [
    { text: 'Division Name', value: 'name', class: 'table-headers' },
    {
      text: ' ',
      value: 'actions',
      sortable: false,
      class: 'table-headers',
      align: 'right',
    },
  ];
  private async created() {
    this.getDivisions();
  }

  private async getDivisions() {
    this.allDivisions = await apiService.getAll<DivisionModel>('/division');
  }

  private async refreshDivisions() {
    this.getDivisions();
  }

  private async saveDivision() {
    if (!this.divisionForm.validate()) {
      return snackbarModule.snackError('Invalid form');
    }
    if (this.isEditing) {
      try {
        let division = await apiService.put<DivisionModel>(
          '/division',
          this.divisionModel.divisionId,
          this.divisionModel
        );
        this.divisionModel = new DivisionModel();
        this.closeDialog();
        this.$emit('refreshProducts');
        return snackbarModule.snackSuccess('Division edited successfully');
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
    } else {
      try {
        await apiService.post('/division', this.divisionModel).then(() => {
          return snackbarModule.snackSuccess('Division added successfully');
        });
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
      this.$emit('refreshProducts');
      this.closeDialog();
    }
  }
  private async editDivision(item: any) {
    this.divisionModel = item;
    this.dialog = true;
    this.isEditing = true;
  }
  private deleteDivision(item: any) {
    this.divisionModel = item;
    this.deleteDivisionDialog = true;
  }
  private async confirmDeleteDivision() {
    let divisionName = this.divisionModel.name;
    try {
      let division = await apiService.delete(
        '/division',
        this.divisionModel.divisionId
      );
      this.closeDialog();
      this.$emit('refreshProducts');
      return snackbarModule.snackSuccess(
        `Division ${divisionName} deleted successfully`
      );
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async closeDialog() {
    this.divisionModel = new DivisionModel();
    this.isEditing = false;
    this.deleteDivisionDialog = false;
    this.dialog = false;
    await this.refreshDivisions();
  }
}
