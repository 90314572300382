
import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import FolderModel from '@/models/FolderModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import { VForm } from '@/types/vForm';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class FolderDataTable extends Vue {
  @Ref() private readonly folderForm!: VForm;
  @Prop(String) readonly id!: string;
  private isEditing = false;
  private search = '';
  private dialog = false;
  private isUploading = false;
  private selectedFile: File | null = null;
  private folderModel: FolderModel = new FolderModel();
  private deleteFolderDialog = false;
  private uploadFileDialog = false;
  private allFolders: Array<FolderModel> = [];
  private rules: any = validations.VALIDATION_RULES;
  private headers = [{}];
  private async created() {
    this.getFolders();
    this.headers = this.isAdmin
      ? [
          { text: 'Folder', value: 'name', class: 'table-headers' },
          {
            text: '',
            value: 'actions',
            sortable: false,
            class: 'table-headers',
            align: 'right',
          },
        ]
      : [{ text: 'Folder', value: 'name', class: 'table-headers' }];
  }

  private async getFolders() {
    let folders = await apiService.getAll<FolderModel>('/folder');

    if (this.searchedFileIds.length > 0) {
      this.allFolders = folders.filter((folder) =>
        this.searchedFileIds.includes(folder.folderId.toString())
      );
    } else {
      this.allFolders = folders.filter(
        (folder) => folder.parentFolderId == null
      );
    }
  }
  private get searchedFileIds(): string[] {
    return this.$store.getters.searchedFileIds;
  }
  private async uploadFile() {
    if (!this.selectedFile) return;
    this.isUploading = true;
    try {
      const response = await apiService
        .uploadFile('document', this.selectedFile)
        .then(() => {
          this.isUploading = false;
          this.closeDialog();
          return snackbarModule.snackSuccess('File uploaded successfully');
        });
    } catch {
      this.isUploading = false;
    }
  }

  private async saveFolder() {
    if (!this.folderForm.validate()) {
      return snackbarModule.snackError('Invalid form');
    }
    if (this.isEditing) {
      try {
        let folder = await apiService.put<FolderModel>(
          '/folder',
          this.folderModel.folderId,
          this.folderModel
        );
        this.folderModel = new FolderModel();
        this.closeDialog();
        this.$emit('refreshProducts');
        return snackbarModule.snackSuccess('Folder edited successfully');
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
    } else {
      try {
        await apiService.post('/folder', this.folderModel).then(() => {
          return snackbarModule.snackSuccess('Folder added successfully');
        });
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
      this.$emit('refreshProducts');
      this.closeDialog();
    }
  }
  private async mounted() {
    this.$watch(
      () => this.$store.getters.searchedFileIds,
      this.watchSearchedFileIds
    );
  }
  private async watchSearchedFileIds(newValues: string[], oldValues: string[]) {
    if (newValues !== oldValues) {
      await this.getFolders();
    }
  }
  private async editFolder(item: any) {
    this.folderModel = item;
    this.dialog = true;
    this.isEditing = true;
  }
  private deleteFolder(item: any) {
    this.folderModel = item;
    this.deleteFolderDialog = true;
  }
  private async confirmDeleteFolder() {
    let folderName = this.folderModel.name;
    try {
      let folder = await apiService.delete(
        '/folder',
        this.folderModel.folderId
      );
      this.closeDialog();
      this.$emit('refreshProducts');
      return snackbarModule.snackSuccess(
        `Folder ${folderName} deleted successfully`
      );
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async closeDialog() {
    this.folderModel = new FolderModel();
    this.isEditing = false;
    this.deleteFolderDialog = false;
    this.uploadFileDialog = false;
    this.dialog = false;
    await this.getFolders();
  }

  private async onRowClick(item: any) {
    if (this.$route.name === 'Admin') {
      this.$router.push({
        name: 'Folder',
        params: { id: item.folderId, Admin: 'admin' },
      });
    } else {
      this.$router.push({
        name: 'Folder',
        params: { id: item.folderId, Admin: 'home' },
      });
    }
  }
  get isAdmin() {
    return this.$store.getters.isAdmin;
  }
}
