
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import FileForApprovalModel from '@/models/FileForApprovalModel';
import FolderModel from '@/models/FolderModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import TagModel from '@/models/TagModel';
import { VForm } from '@/types/vForm';
import { ref } from 'vue';
import DocumentTagModel from '@/models/DocumentTagModel';
import DocumentModel from '@/models/DocumentModel';
import { BlobServiceClient } from '@azure/storage-blob';
import environment from '@/environment';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class FilesForApprovalDataTable extends Vue {
  @Ref() private readonly filesForApprovalForm!: VForm;
  @Prop(String) readonly id!: string;
  @Watch('active')
  onActiveChanged(value: string[]) {
    if (value.length === 0) {
      this.approvalFolderId = '';
    } else {
      this.approvalFolderId = value[0];
    }
  }
  private isEditing = false;
  private search = '';
  private folderSearch = '';
  private approvalFolderId = '';
  private allTags: Array<TagModel> = [];
  private currentTagsForDocument: Array<TagModel> = [];
  private selectedTags: Array<Guid> = [];
  private documentTag: DocumentTagModel = new DocumentTagModel();
  private TagModel: TagModel = new TagModel();
  private documentModel: DocumentModel = new DocumentModel();
  private currentFileId = '';
  private currentFileName = '';
  private currentFileEditId = Guid.createEmpty();
  private rejectionReason = '';
  private dialog = false;
  private tagsDialog = false;
  private videoDialog = false;
  private isDownloading = false;
  private deleteDialog = false;
  private editFileDialog = false;
  private approvalDialog = false;
  private FileForApprovalModel: FileForApprovalModel =
    new FileForApprovalModel();
  private allFilesForApproval: Array<FileForApprovalModel> = [];
  private allFolders: Array<FolderModel> = [];
  private rules: any = validations.VALIDATION_RULES;
  private currentVideo?: any = null;
  private currentVideoName = '';
  private isPlaying = false;
  private tempFileId = Guid.createEmpty();
  private tempFileName = '';
  private downloadingIds: Array<string> = [];
  private tree: number[] = [];
  private active = [];
  private viewingIds: Array<string> = [];
  private headers = [
    { text: 'Files to be approved', value: 'name', class: 'table-headers' },
    {
      text: '',
      value: 'actions',
      sortable: false,
      class: 'table-headers',
      align: 'right',
    },
  ];

  private async removeTag(id: Guid) {
    try {
      this.FileForApprovalModel.documentTags =
        this.FileForApprovalModel.documentTags.filter(
          (x) => x.documentTagId != id
        );
      await apiService.delete('/documenttag', id);
      this.getTags();
      snackbarModule.snackSuccess('Tags removed successfully');
    } catch (error: any) {
      snackbarModule.snackError(`${error.response.data.error}`);
    }
  }

  private updateTagList(tag: TagModel) {
    // tag.selected = !tag.selected;

    const index = this.selectedTags.indexOf(tag.tagId);
    if (index !== -1) {
      this.selectedTags.splice(index, 1);
    } else {
      this.selectedTags.push(tag.tagId);
    }
  }

  private async openTagsDialog(id: Guid) {
    this.editFileDialog = false;
    this.tagsDialog = true;
    this.getTags();
  }

  private async getTags() {
    const tags = await apiService.getAll<TagModel>('/tag');
    const documentTags = this.FileForApprovalModel.documentTags;

    this.allTags = tags.filter((x) => {
      return !documentTags.some((u) => u.tagId === x.tagId.toString());
    });
  }

  private async confirmAddTagsToDocument() {
    if (this.selectedTags.length > 0) {
      try {
        this.documentTag.tagIds = this.selectedTags;
        this.documentTag.documentId = this.currentFileEditId;
        await apiService.postDocumentTagIds('/documenttag/', this.documentTag);
        snackbarModule.snackSuccess('Tags added successfully');
        this.tagsDialog = false;
        this.editFileDialog = true;
        let document = await apiService.get<DocumentModel>(
          '/document/getDocument',
          this.tempFileId
        );
        this.documentModel = document;
        this.FileForApprovalModel.documentId = Guid.parse(document.documentId);
        this.FileForApprovalModel.documentTags = document.documentTags;
        this.getTags();
        this.selectedTags = [];
      } catch (error: any) {
        snackbarModule.snackError(`${error.response.data.error}`);
      }
    } else {
      snackbarModule.snackError(`Please select a tag.`);
    }
  }

  private editFile(fileId: Guid, filename: string) {
    this.tempFileId = fileId;
    let currentFile = this.allFilesForApproval.find(
      (f) => f.documentId == fileId
    );
    if (currentFile) {
      this.FileForApprovalModel = currentFile;
    }

    this.editFileDialog = true;
    this.currentFileEditId = fileId;
    this.currentFileName = filename;
  }
  private openApprovalDialog(id: string) {
    this.currentFileId = id;
    this.approvalDialog = true;
    this.getFolders();
  }
  private openDeleteDialog(id: string) {
    this.currentFileId = id;
    this.deleteDialog = true;
  }
  private async changeFileName() {
    try {
      await apiService.changeFileName(
        this.currentFileEditId,
        this.currentFileName
      );
      await this.closeDialog();
      await this.getFilesForApproval();
      snackbarModule.snackSuccess('File name changed successfully');
    } catch {
      snackbarModule.snackError('Failed to change file name');
    }
  }
  private async confirmFileDeletion() {
    try {
      await apiService.delete('/document', Guid.parse(this.currentFileId));
      this.closeDialog();
      return snackbarModule.snackSuccess(`File deleted successfully`);
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async confirmFileApproval() {
    try {
      await apiService.approveFile(
        '/document',
        Guid.parse(this.currentFileId),
        Guid.parse(this.approvalFolderId)
      );
      this.closeDialog();
      return snackbarModule.snackSuccess(`File approved successfully`);
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async created() {
    await this.getFolders();
    await this.getFilesForApproval();
  }
  private async getFolders() {
    this.allFolders = await apiService.getAll<FolderModel>('/folder');
    let folderMap: { [key: string]: any } = {};

    // map all folders and create children arrays
    this.allFolders.forEach((folder) => {
      folderMap[folder.folderId.toString()] = { ...folder, children: [] };
    });
    // populate parent folder's children property with subfolders
    this.allFolders.forEach((folder) => {
      if (folder.parentFolderId) {
        const parentFolder = folderMap[folder.parentFolderId.toString()];
        if (parentFolder) {
          parentFolder.children.push(folderMap[folder.folderId.toString()]);
        }
      }
    });

    // filter top-level folders
    this.allFolders = this.allFolders.filter(
      (folder) => !folder.parentFolderId
    );

    // use the mapped versions with children
    this.allFolders = this.allFolders.map(
      (folder) => folderMap[folder.folderId.toString()]
    );
  }
  private async getFilesForApproval() {
    let response = await apiService.getAll<FileForApprovalModel>('/document');

    this.allFilesForApproval = response.filter((file) => {
      return file.folderId == null;
    });
  }
  private async downloadFile(id: string): Promise<void> {
    if (this.downloadingIds.includes(id)) {
      snackbarModule.snackError(
        'file is already being downloaded, please wait'
      );
      return;
    }
    this.downloadingIds.push(id);
    this.isDownloading = true;
    try {
      await apiService.incrementDocumentCount(Guid.parse(id));
      const { file, filename } = await apiService.getFile(
        'document',
        Guid.parse(`${id}`)
      );
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      let fileDownloading: any = this.allFilesForApproval.find(
        (f) => f.documentId.toString() == id
      );
      link.setAttribute('download', fileDownloading.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      this.isDownloading = false;
      this.downloadingIds = this.downloadingIds.filter((d) => d !== id);
      snackbarModule.snackSuccess('File downloaded successfully');
    } catch {
      this.isDownloading = false;
      this.downloadingIds = this.downloadingIds.filter((d) => d !== id);
      snackbarModule.snackError('File download failed');
    }
  }
  private async viewPDF(id: string): Promise<void> {
    if (this.viewingIds.includes(id)) {
      snackbarModule.snackError('file is already being opened, please wait');
      return;
    }
    this.viewingIds.push(id);
    try {
      await apiService.incrementDocumentCount(Guid.parse(id));
      const { file, filename } = await apiService.getFile(
        'document',
        Guid.parse(`${id}`)
      );
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      let fileDownloading: any = this.allFilesForApproval.find(
        (f) => f.documentId.toString() == id
      );
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      this.viewingIds = this.viewingIds.filter((d) => d !== id);
      snackbarModule.snackSuccess('File successfully opened');
    } catch (error) {
      snackbarModule.snackError('Failed to open file');
      this.viewingIds = this.viewingIds.filter((d) => d !== id);
    }
  }
  private async refreshFilesForApproval() {
    this.getFilesForApproval();
    this.$emit('refreshFilesForApproval');
  }
  private async editFilesForApproval(item: any) {
    this.FileForApprovalModel = item;
    this.dialog = true;
    this.isEditing = true;
  }
  private deleteFilesForApproval(item: any) {
    this.FileForApprovalModel = item;
    this.deleteDialog = true;
  }
  private async closeDialog() {
    this.FileForApprovalModel = new FileForApprovalModel();
    this.isEditing = false;
    this.approvalDialog = false;
    this.editFileDialog = false;
    this.deleteDialog = false;
    this.approvalFolderId = '';
    this.currentFileId = '';
    await this.refreshFilesForApproval();
  }
  private async closeTagDialog() {
    this.selectedTags = [];
    this.tagsDialog = false;
    this.editFileDialog = true;
  }
  private async playVideo(id: string): Promise<void> {
    this.isPlaying = true;
    await apiService.incrementDocumentCount(Guid.parse(id));
    try {
      // Your SAS token
      const sasToken = environment.SAS_TOKEN!;

      // Your blob storage URL
      const blobServiceUrl = environment.BLOB_SERVICE_URL!;

      // Name of the container
      const containerName = environment.BLOB_CONTAINER!;

      // Get BlobServiceClient
      const blobServiceClient = new BlobServiceClient(
        `${blobServiceUrl}?${sasToken}`
      );

      // Get ContainerClient
      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      // Get the blob client
      const blockBlobClient = containerClient.getBlockBlobClient(id);

      // Get the blob URL
      const url = blockBlobClient.url;

      this.currentVideo = url;
      this.videoDialog = true;
      this.isPlaying = false;

      await this.getFilesForApproval();
    } catch (error) {
      this.isPlaying = false;
    }
  }
  private stopVideo() {
    this.currentVideo = '';
    this.videoDialog = false;
  }
}
