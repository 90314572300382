export default class UserTokenModel {
  public accessToken: string = "";
  public refreshToken: string = "";

  constructor(data?: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
