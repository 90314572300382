
import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import FileModel from '@/models/FileModel';
import FolderModel from '@/models/FolderModel';
import DocumentModel from '@/models/DocumentModel';
import apiService from '@/services/apiService';
import { Guid } from 'guid-typescript';
import SnackbarModule from '@/store/modules/snackbarModule';
import { getModule } from 'vuex-module-decorators';
import validations from '@/validations';
import { VForm } from '@/types/vForm';
import { BlobServiceClient } from '@azure/storage-blob';
import environment from '@/environment';
import TagModel from '@/models/TagModel';
import DocumentTagModel from '@/models/DocumentTagModel';
import NewsModel from '@/models/NewsModel';

const snackbarModule = getModule(SnackbarModule);

@Component
export default class FileDataTable extends Vue {
  @Ref() private readonly fileForm!: VForm;
  @Watch('active')
  onActiveChanged(value: string[]) {
    if (value.length === 0) {
      this.transferFolderId = '';
    } else {
      this.transferFolderId = value[0];
    }
  }

  private allTags: Array<TagModel> = [];
  private currentTagsForDocument: Array<TagModel> = [];
  private TagModel: TagModel = new TagModel();
  private selectedTags: Array<Guid> = [];
  private documentTag: DocumentTagModel = new DocumentTagModel();
  private currentFileEditId = Guid.createEmpty();
  private FileModel: FileModel = new FileModel();
  private allFilesForApproval: Array<FileModel> = [];
  private documentModel: DocumentModel = new DocumentModel();
  private newsModel: NewsModel = new NewsModel();
  private tempFileId = Guid.createEmpty();
  private latestNews: Array<NewsModel> = [];
  private tree: number[] = [];
  private active = [];
  private transferFolderId = '';
  private isEditing = false;
  private downloadingIds: Array<string> = [];
  private viewingIds: Array<string> = [];
  private search = '';
  private currentFileName = '';
  private currentFileId = Guid.createEmpty();
  private fileTranserDialog = false;
  private uploadDialog = false;
  private videoDialog = false;
  private tagsDialog = false;
  private isUploading = false;
  private newsDialog = false; // Latest News Dialog
  private fileSuccessfulDialog = false;
  private editFileDialog = false;
  private emailNotificationDialog = false;
  private showProgressModal = false;
  private isPlaying = false;
  private fileModel: FileModel = new FileModel();
  private deleteFileDialog = false;
  private allFiles: Array<FileModel> = [];
  private searchResults: Array<FileModel> = [];
  private allFolderFiles: Array<FileModel> = [];
  private allFolders: Array<FolderModel> = [];
  private selectedFiles: Array<File> = [];
  private rules: any = validations.VALIDATION_RULES;
  private currentVideo?: any = null;
  private currentVideoName = '';
  private randomGuid: string = '';
  private uploadProgress: any = 0;
  private headers = [{}];
  private async created() {
    this.headers = this.isAdmin
      ? [
          {
            text: 'Filename',
            value: 'name',
            class: 'table-headers',
            width: '25%'
          },
          {
            text: 'Date',
            value: 'createdString',
            class: 'table-headers',
            width: '10%'
          },
          {
            text: 'Total Views',
            value: 'count',
            class: 'table-headers',
            align: 'right',
            width: '10%'
          },
          {
            text: ' ',
            value: 'actions',
            sortable: false,
            class: 'table-headers',
            align: 'right',
            width: '55%'
          },
        ]
      : [
          { text: 'Filename', value: 'name', class: 'table-headers' },
          { text: 'Date', value: 'createdString', class: 'table-headers' },
          {
            text: '',
            value: 'actions',
            sortable: false,
            class: 'table-headers',
            align: 'right',
          },
        ];
    await this.getFiles();
    await this.getFolders();
    await this.getNews();
  }

  private async getNews() {
    this.newsModel = await apiService.getNews<NewsModel>('/news');
  }

  private updateTagList(tag: TagModel) {
    const index = this.selectedTags.indexOf(tag.tagId);
    if (index !== -1) {
      this.selectedTags.splice(index, 1);
    } else {
      this.selectedTags.push(tag.tagId);
    }
  }

  private async closeTagDialog() {
    this.selectedTags = [];
    this.tagsDialog = false;
    this.editFileDialog = true;
  }

  private async confirmAddTagsToDocument() {
    if (this.selectedTags.length > 0) {
      try {
        this.documentTag.tagIds = this.selectedTags;
        this.documentTag.documentId = this.currentFileEditId;
        await apiService.postDocumentTagIds('/documenttag/', this.documentTag);
        snackbarModule.snackSuccess('Tags added successfully');
        this.tagsDialog = false;
        this.editFileDialog = true;
        let document = await apiService.get<DocumentModel>(
          '/document/getDocument',
          this.tempFileId
        );
        this.documentModel = document;
        this.FileModel.documentId = Guid.parse(document.documentId);
        this.FileModel.documentTags = document.documentTags;
        this.getTags();
        this.selectedTags = [];
      } catch (error: any) {
        snackbarModule.snackError(`${error.response.data.error}`);
      }
    } else {
      snackbarModule.snackError(`Please select a tag.`);
    }
  }

  private async openTagsDialog(id: Guid) {
    this.editFileDialog = false;
    this.tagsDialog = true;
    this.getTags();
  }

  private async removeTag(id: Guid) {
    try {
      this.FileModel.documentTags = this.FileModel.documentTags.filter(
        (x) => x.documentTagId != id
      );
      await apiService.delete('/documenttag', id);
      this.getTags();
      snackbarModule.snackSuccess('Tags removed successfully');
    } catch (error: any) {
      snackbarModule.snackError(`${error.response.data.error}`);
    }
  }

  private async getTags() {
    const tags = await apiService.getAll<TagModel>('/tag');
    const documentTags = this.FileModel.documentTags;

    this.allTags = tags.filter((x) => {
      return !documentTags.some((u) => u.tagId === x.tagId.toString());
    });
  }

  private async getFolders() {
    this.allFolders = await apiService.getAll<FolderModel>('/folder');
    let folderMap: { [key: string]: any } = {};

    // map all folders and create children arrays
    this.allFolders.forEach((folder) => {
      folderMap[folder.folderId.toString()] = { ...folder, children: [] };
    });
    // populate parent folder's children property with subfolders
    this.allFolders.forEach((folder) => {
      if (folder.parentFolderId) {
        const parentFolder = folderMap[folder.parentFolderId.toString()];
        if (parentFolder) {
          parentFolder.children.push(folderMap[folder.folderId.toString()]);
        }
      }
    });

    // filter top-level folders
    this.allFolders = this.allFolders.filter(
      (folder) => !folder.parentFolderId
    );

    // use the mapped versions with children
    this.allFolders = this.allFolders.map(
      (folder) => folderMap[folder.folderId.toString()]
    );
  }

  private async confirmFileTransfer() {
    try {
      await apiService.approveFile(
        '/document',
        this.currentFileId,
        Guid.parse(this.transferFolderId)
      );
      this.closeDialog();
      await this.getFiles();
      await this.onSearch();
      this.$router.push({
        name: 'Folder',
        params: { id: this.transferFolderId, Admin: 'admin' },
      });
      return snackbarModule.snackSuccess(`File transerfered successfully`);
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }

  private async getFiles() {
    if (this.$route.name == 'Folder') {
      this.allFiles = await apiService.getAllById<FileModel>(
        '/document/allBy',
        Guid.parse(this.$route.params.id)
      );
    } else {
      this.allFiles = await apiService.getAll<FileModel>('/document/approved');
    }
    this.searchResults = this.allFiles;
  }
  private onSearch() {
    //get all documents =  this.allFiles
    //get all tags  this.allTags
    //for each tag in document - if (tag in search == tag in document) - return document
    const searchQuery = this.search.toLowerCase();
    const foundFiles = this.allFiles.filter((file) => {
      return (
        file.name.toLowerCase().includes(searchQuery) ||
        file.newTags.toLowerCase().includes(searchQuery)
      );
    });

    if (foundFiles.length > 0) {
      let foundFileIds = foundFiles.map((file) => file.folderId);
      this.$store.dispatch('updateSearchedFileIds', foundFileIds);
    } else {
      this.$store.dispatch('updateSearchedFileIds', []);
    }
    if (this.search.length === 0) {
      this.searchResults = this.allFiles;
    } else {
      this.searchResults = foundFiles;
    }
  }

  private openFileTransferDialog(id: Guid) {
    this.currentFileId = id;
    this.fileTranserDialog = true;
    this.getFolders();
  }

  private async downloadFile(id: string): Promise<void> {
    if (this.downloadingIds.includes(id)) {
      snackbarModule.snackError(
        'file is already being downloaded, please wait'
      );
      return;
    }
    this.downloadingIds.push(id);
    try {
      await apiService.incrementDocumentCount(Guid.parse(id));
      await apiService.postDocumentActivity(Guid.parse(id));
      const { file, filename } = await apiService.getFile(
        'document',
        Guid.parse(`${id}`)
      );
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      let fileDownloading: any = this.allFiles.find(
        (f) => f.documentId.toString() == id
      );
      link.setAttribute('download', fileDownloading.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      this.downloadingIds = this.downloadingIds.filter((d) => d !== id);
      await this.getFiles();
      await this.onSearch();
      snackbarModule.snackSuccess('File successfully downloaded');
    } catch (error) {
      snackbarModule.snackError('Failed to download file');
      this.downloadingIds = this.downloadingIds.filter((d) => d !== id);
    }
  }
  private async viewPDF(id: string): Promise<void> {
    if (this.viewingIds.includes(id)) {
      snackbarModule.snackError('file is already being opened, please wait');
      return;
    }
    this.viewingIds.push(id);
    try {
      await apiService.incrementDocumentCount(Guid.parse(id));
      await apiService.postDocumentActivity(Guid.parse(id));
      const { file, filename } = await apiService.getFile(
        'document',
        Guid.parse(`${id}`)
      );
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      let fileDownloading: any = this.allFiles.find(
        (f) => f.documentId.toString() == id
      );
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      this.viewingIds = this.viewingIds.filter((d) => d !== id);
      await this.getFiles();
      await this.onSearch();
      snackbarModule.snackSuccess('File successfully opened');
    } catch (error) {
      snackbarModule.snackError('Failed to open file');
      this.viewingIds = this.viewingIds.filter((d) => d !== id);
    }
  }

  private async playVideo(id: string): Promise<void> {
    this.isPlaying = true;
    await apiService.incrementDocumentCount(Guid.parse(id));
    try {
      // Your SAS token
      const sasToken = environment.SAS_TOKEN!;

      // Your blob storage URL
      const blobServiceUrl = environment.BLOB_SERVICE_URL!;

      // Name of the container
      const containerName = environment.BLOB_CONTAINER!;

      // Get BlobServiceClient
      const blobServiceClient = new BlobServiceClient(
        `${blobServiceUrl}?${sasToken}`
      );

      // Get ContainerClient
      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      // Get the blob client
      const blockBlobClient = containerClient.getBlockBlobClient(id);

      // Get the blob URL
      const url = blockBlobClient.url;

      this.currentVideo = url;
      this.videoDialog = true;
      this.isPlaying = false;

      await this.getFiles();
      await this.onSearch();
    } catch (error) {
      this.isPlaying = false;
    }
  }

  private beforeDestroy() {
    if (this.currentVideo) {
      window.URL.revokeObjectURL(this.currentVideo);
    }
  }
  private async stopVideo() {
    this.currentVideo = '';
    this.videoDialog = false;
    await this.getFiles();
    await this.onSearch();
  }

  private async uploadFiles() {
    if (!this.selectedFiles || this.selectedFiles.length === 0) return;
    this.uploadDialog = false;

    // Your SAS token
    const sasToken = environment.SAS_TOKEN!;

    // Your blob storage URL
    const blobServiceUrl = environment.BLOB_SERVICE_URL!;

    // Name of the container
    const containerName = environment.BLOB_CONTAINER!;

    // Get BlobServiceClient
    const blobServiceClient = new BlobServiceClient(
      `${blobServiceUrl}?${sasToken}`
    );

    // Get ContainerClient
    const containerClient = blobServiceClient.getContainerClient(containerName);

    // Show progress modal
    this.showProgressModal = true;

    // Initialize upload progress array
    this.uploadProgress = [];

    // Upload each file
    await Promise.all(
      this.selectedFiles.map(async (file) => {
        //@ts-ignore
        const guid = crypto.randomUUID();

        // The name you want to give to the blob
        const blobName = guid;

        // Get BlockBlobClient
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        // Set the content type of the uploaded file
        const blobHTTPHeaders = {
          blobContentType: file.type,
        };

        const fileSize = file.size;

        try {
          // Upload data to the blob
          const uploadBlobResponse = await blockBlobClient.upload(
            file,
            fileSize,
            {
              blobHTTPHeaders,
              onProgress: (ev) => {
                const progress = Math.round((ev.loadedBytes / fileSize) * 100);

                // Find the file progress object by file name
                const fileProgress = this.uploadProgress.find(
                  (progressObj: { fileName: string; progress: number }) =>
                    progressObj.fileName === file.name
                );
                // Update the progress for the file
                if (fileProgress) {
                  fileProgress.progress = progress;
                } else {
                  // Create a new progress object if it doesn't exist
                  this.uploadProgress.push({
                    fileName: file.name,
                    progress,
                  });
                }
              },
            }
          );

          if (uploadBlobResponse.requestId != null) {
            var documentModel = new DocumentModel();
            documentModel.fileName = file.name;
            documentModel.contentType = file.type;
            documentModel.documentId = guid;

            if (!this.isAdmin) {
              try {
                const response = await apiService.post(
                  'document',
                  documentModel
                );
              } catch (error) {
                this.isUploading = false;
              }
            } else {
              const folderId = this.$route.params.id;
              documentModel.folderId = folderId;

              try {
                const response = await apiService.post(
                  'document',
                  documentModel
                );
                await this.getFiles();
              } catch {
                snackbarModule.snackError('Document failed to upload');
              }
            }
          }
        } catch {
          snackbarModule.snackError('Document failed to upload');
        }
      })
    );
    await this.getFiles();
    this.fileSuccessfulDialog = true;
    // Hide progress modal
    this.showProgressModal = false;
  }
  private async saveFile() {
    if (!this.fileForm.validate()) {
      return snackbarModule.snackError('Invalid form');
    }
    if (this.isEditing) {
      try {
        let file = await apiService.put<FileModel>(
          '/file',
          this.fileModel.documentId,
          this.fileModel
        );
        this.fileModel = new FileModel();
        this.closeDialog();
        this.$emit('refreshProducts');
        return snackbarModule.snackSuccess('File edited successfully');
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
    } else {
      try {
        await apiService.post('/file', this.fileModel).then(() => {
          return snackbarModule.snackSuccess('File added successfully');
        });
      } catch (error: any) {
        return snackbarModule.snackError(`${error.response.data.error}`);
      }
      this.$emit('refreshProducts');
      this.closeDialog();
    }
  }
  private deleteFile(item: any) {
    this.fileModel = item;
    this.deleteFileDialog = true;
  }
  private async confirmDeleteFile() {
    let fileName = this.fileModel.name;
    try {
      let file = await apiService.delete(
        '/document',
        this.fileModel.documentId
      );
      this.closeDialog();
      await this.getFiles();
      await this.onSearch();
      this.$emit('refreshProducts');
      return snackbarModule.snackSuccess(
        `File ${fileName} deleted successfully`
      );
    } catch (error: any) {
      this.closeDialog();
      return snackbarModule.snackError(`${error.response.data.error}`);
    }
  }
  private async changeFileName() {
    try {
      await apiService.changeFileName(
        this.currentFileEditId,
        this.currentFileName
      );
      await this.closeDialog();
      await this.getFiles();
      await this.onSearch();
      snackbarModule.snackSuccess('File name changed successfully');
    } catch {
      snackbarModule.snackError('Failed to change file name');
    }
  }
  private editFile(item: FileModel) {
    this.FileModel = item;
    this.tempFileId = item.documentId;
    this.editFileDialog = true;
    this.currentFileEditId = item.documentId;
    this.currentFileName = item.name;
  }
  private async closeDialog() {
    this.fileModel = new FileModel();
    this.selectedFiles = [];
    // this.selectedTags = [];
    this.isEditing = false;
    this.deleteFileDialog = false;
    this.editFileDialog = false;
    this.uploadDialog = false;
    this.currentFileId = Guid.createEmpty();
    this.currentFileName = '';
    this.fileTranserDialog = false;
    await this.getFiles();
    await this.onSearch();
  }

  private async cancelUpload() {
    // Hide progress modal and cancel upload if needed
    this.showProgressModal = false;
  }

  get isAdmin() {
    return this.$store.getters.isAdmin;
  }
}
